import { FunctionComponent, useRef } from "react";

const FinalPc: FunctionComponent = () => {
  const servicesRef = useRef<HTMLDivElement>(null);
  const aboutUsRef = useRef<HTMLDivElement>(null);
  const contactRef = useRef<HTMLDivElement>(null);

  const scrollToServices = () => {
    if (servicesRef.current) {
      servicesRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToAboutUs = () => {
    if (aboutUsRef.current) {
      aboutUsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToContact = () => {
    if (contactRef.current) {
      contactRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div className="w-full relative bg-icon-color box-border overflow-hidden flex flex-col items-center justify-start text-center text-6xl text-teal font-semi-heading border-[1px] border-solid border-black">
      <div className="self-stretch bg-azure flex flex-row items-center justify-between py-5 px-[223px] text-gray font-body-2 sm:pl-5 sm:pr-5 sm:box-border">
        <img
          className="w-[247px] relative h-[114px] object-cover sm:w-[200px] sm:h-[100px]"
          alt=""
          src="/screenshot-20240229-112211removebgpreview-1@2x.png"
        />
        <div className="flex flex-row items-end justify-start gap-[49px]">
          <div className="w-[473.5px] relative h-[40.6px] sm:hidden">
            <a className="[text-decoration:none] absolute top-[0.6px] left-[0px] leading-[160%] capitalize font-semibold text-[inherit] [transform:_rotate(-0.25deg)] [transform-origin:0_0]" onClick={scrollToServices}>
              Services
            </a>
            <div className="absolute top-[0.6px] left-[160.2px] leading-[160%] capitalize font-semibold [transform:_rotate(-0.25deg)] [transform-origin:0_0]">
              <a onClick={scrollToAboutUs}>
                About Us
              </a>
            </div>
            <div className="absolute top-[0.6px] left-[329.3px] leading-[160%] capitalize font-semibold [transform:_rotate(-0.25deg)] [transform-origin:0_0]">
              <a onClick={scrollToContact}>
                Contact Us
                </a>
            </div>
          </div>
          <img
            className="h-[45px] w-[45px] relative overflow-hidden shrink-0 hidden sm:flex"
            alt=""
            src="/firrgrid.svg"
          />
        </div>
      </div>
      <div className="self-stretch bg-azure flex flex-col items-center justify-start py-[70px] px-[223px] gap-[24px] text-49xl md:pl-[120px] md:pr-[120px] md:box-border sm:w-auto sm:[align-self:center] sm:box-border">
        <div className="w-[768px] relative capitalize font-semibold flex items-center justify-center sm:text-21xl sm:w-[350px] sm:ml-4 sm:mr-4">
          {" "}
          ITR Filing Services for
        </div>
        <div className="w-[687px] h-20 relative text-6xl leading-[160%] capitalize font-body-2 text-gray inline-block sm:text-md sm:w-[350px] sm:flex-1">
          Salaried Taxpayers, Capital gain taxation, Share traders, Futures and
          Options traders.
        </div>
        <div className="flex flex-row items-center justify-center pt-10 px-0 pb-0 gap-[100px] text-left text-2xl text-text-light font-text-md-medium">
          <div className="rounded-tl-none rounded-tr-3xs rounded-br-3xs rounded-bl-none h-[68px] overflow-hidden hidden flex-row items-start justify-start">
            <input
              className="[border:none] [outline:none] font-body-2 text-xl bg-icon-color rounded-tl-md rounded-tr-none rounded-br-none rounded-bl-md box-border h-[68px] flex flex-row items-start justify-start py-[27px] px-11 text-silver border-t-[1px] border-solid border-darkgray border-b-[1px] border-l-[1px]"
              placeholder="Your Email / Phone no."
              type="text"
            />
            <div className="w-[200.6px] h-[68px] overflow-hidden shrink-0 flex flex-col items-center justify-center">
              <div className="self-stretch flex-1 overflow-hidden flex flex-row items-start justify-start">
                <div className="self-stretch flex-1 bg-sandybrown shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] overflow-hidden flex flex-row items-center justify-center py-0 px-5 relative gap-[8px]">
                  <div className="w-px absolute !m-[0] top-[calc(50%_-_0px)] left-[calc(50%_-_1.3px)] rounded-980xl bg-peru h-px overflow-hidden shrink-0 opacity-[0] z-[0]" />
                  <div className="hidden flex-row items-center justify-center z-[1]">
                    <a href="https://wa.me/918952056684?text=Hi%20Krisna.%5CnThis%20in%20whatsapp%20integration%20testing." target="_blank">
                    <img
                      className="w-[41px] relative h-[41px]"
                      alt=""
                      src="/whatsapp.svg"
                    />
                    </a>
                  </div>
                  <div className="relative leading-[24px] font-medium z-[2]">
                    Contact Me
                  </div>
                </div>
              </div>
            </div>
            
          </div>
          <div className="rounded-3xs shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] overflow-hidden flex flex-col items-center justify-center text-base">
            <div className="overflow-hidden flex flex-row items-start justify-start">
              <a className="bg-mediumseagreen-100 shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] overflow-hidden flex flex-row items-center justify-center py-3 px-5 relative gap-[8px] no-underline transition-all before:absolute before:bottom-0 before:left-0 before:top-0 before:z-0 before:h-full before:w-0 before:bg-green-600 before:transition-all before:duration-500 hover:shadow-green-600 hover:before:left-0 hover:before:w-full"
                href="https://wa.me/918952056684?text=Hi%2C%20I%20am%20looking%20for%20tax%20services." target="_blank"
              >
                <div className="w-px absolute !m-[0] top-[calc(50%_+_0.5px)] left-[calc(50%_-_1px)] rounded-980xl bg-mediumseagreen-200 h-px overflow-hidden shrink-0 opacity-[0] z-[0]" />
                <div className="flex flex-row items-center justify-center z-[1]">
                  <img
                    className="w-[41px] relative h-[41px]"
                    alt=""
                    src="/whatsapp.svg"
                  />
                </div>
                <div className="relative leading-[24px] font-medium z-[2] text-white">
                  Connect on Whatsapp
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="self-stretch bg-lightseagreen flex flex-col items-center justify-start pt-[30px] px-0 pb-0 text-21xl text-icon-color">
        <div className="w-[268.9px] relative capitalize font-semibold inline-block" ref={servicesRef}>
          Services
        </div>
        <div className="w-[1757px] flex flex-row items-start justify-center text-6xl sm:w-auto sm:[align-self:unset] sm:flex-col sm:items-center sm:justify-center">
          <div className="bg-lightseagreen flex flex-col items-center justify-start py-[30px] px-0 gap-[27px]">
            <div className="w-[100.8px] rounded-8xs bg-icon-color h-[117px] flex flex-col items-center justify-center">
              <img
                className="w-[68.8px] relative h-[71.9px]"
                alt=""
                src="/-icon-pencil.svg"
              />
            </div>
            <div className="self-stretch flex flex-col items-center justify-start gap-[13px]">
              <div className="w-[268.9px] relative capitalize font-semibold inline-block">
                Tax Filing
              </div>
              <div className="w-[358.5px] relative text-xl leading-[160%] capitalize font-body-2 inline-block">
                <p className="m-0">Reduce your tax liability</p>
                <p className="m-0">Effortless ITR Filing</p>
                <p className="m-0">Maximum Tax Savings</p>
                <p className="m-0">{` `}</p>
              </div>
            </div>
          </div>
          <div className="bg-lightseagreen flex flex-col items-center justify-start py-[30px] px-0 gap-[27px]">
            <div className="w-[100.8px] rounded-8xs bg-icon-color h-[117px] flex flex-col items-center justify-center">
              <img
                className="w-[73.5px] relative h-[58.5px]"
                alt=""
                src="/vector.svg"
              />
            </div>
            <div className="self-stretch flex flex-col items-center justify-start gap-[13px]">
              <div className="w-[268.9px] relative capitalize font-semibold inline-block">
                Insurance Planning
              </div>
              <div className="w-[358.5px] relative text-xl leading-[160%] capitalize font-body-2 inline-block">
                <p className="m-0">Health Insurance</p>
                <p className="m-0">Term Insurance</p>
              </div>
            </div>
          </div>
          <div className="bg-lightseagreen flex flex-col items-center justify-start py-[30px] px-0 gap-[27px]">
            <div className="w-[100.8px] relative h-[117px]">
              <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-8xs bg-icon-color flex flex-col items-center justify-center">
                <img className="w-0 relative h-0" alt="" />
              </div>
              <img
                className="absolute h-[52.99%] w-[61.51%] top-[23.08%] right-[19.64%] bottom-[23.93%] left-[18.85%] max-w-full overflow-hidden max-h-full"
                alt=""
                src="/firscalculator.svg"
              />
            </div>
            <div className="self-stretch flex flex-col items-center justify-start gap-[13px]">
              <div className="w-[268.9px] relative capitalize font-semibold inline-block">{`Tax Planning `}</div>
              <div className="w-[358.5px] relative text-xl leading-[160%] capitalize font-body-2 inline-block">
                Coming Soon
              </div>
            </div>
          </div>
          <div className="bg-lightseagreen flex flex-col items-center justify-start py-[30px] px-0 gap-[27px]">
            <div className="w-[100.7px] h-[117px] flex flex-row items-center justify-start">
              <div className="rounded-lg bg-icon-color overflow-hidden flex flex-col items-center justify-center py-6 px-4">
                <img
                  className="w-[68.7px] relative h-[69px]"
                  alt=""
                  src="/vector.svg"
                />
              </div>
            </div>
            <div className="self-stretch flex flex-col items-center justify-start gap-[13px]">
              <div className="w-[268.9px] relative capitalize font-semibold inline-block">
                Investment Ideas
              </div>
              <div className="w-[358.5px] relative text-xl leading-[160%] capitalize font-body-2 inline-block">
                Coming Soon
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="self-stretch bg-azure flex flex-col items-center justify-start pt-0 px-0 pb-[50px] gap-[20px] sm:w-auto sm:[align-self:unset] sm:pb-0 sm:box-border">
        <div className="self-stretch bg-azure flex flex-row items-start justify-center pt-[50px] px-0 pb-[30px] gap-[90px] sm:flex-col sm:gap-[30px] sm:items-center sm:justify-center sm:pb-0">
          <div className="flex flex-col items-center justify-start gap-[15px]">
            <div className="flex flex-row items-center justify-center gap-[20px]">
              <div className="flex flex-row items-end justify-center">
                <img
                  className="w-[43.8px] relative h-[38.7px]"
                  alt=""
                  src="/vector.svg"
                />
              </div>
              <div className="relative capitalize font-semibold">
                Expert Assistance
              </div>
            </div>
            <div className="w-[358px] relative text-xl leading-[160%] font-body-2 text-black inline-block h-[184px] shrink-0">
              Our team of experts is always ready to help you with any queries
              or concerns you may have. We are focused to serve you as best
              assistant for filing the ITR
            </div>
          </div>
          <div className="flex flex-col items-center justify-start gap-[15px]">
            <div className="flex flex-row items-center justify-center gap-[20px]">
              <img
                className="w-[39.8px] relative h-10 overflow-hidden shrink-0"
                alt=""
                src="/firrtimecheck.svg"
              />
              <div className="relative capitalize font-semibold">
                Timely Filing
              </div>
            </div>
            <div className="w-[280.8px] relative text-xl leading-[160%] font-body-2 text-black inline-block h-[184px] shrink-0">
              Never miss the deadline again. Once you get in touch with us, we
              will timely notify you until your ITR is filed
            </div>
          </div>
          <div className="flex flex-col items-center justify-start gap-[15px]">
            <div className="flex flex-row items-center justify-center gap-[20px]">
              <img
                className="w-[39.8px] relative h-10 overflow-hidden shrink-0 hidden"
                alt=""
                src="/firrtimecheck.svg"
              />
              <img
                className="w-[46.8px] relative h-[47px] overflow-hidden shrink-0"
                alt=""
                src="/firsshieldcheck.svg"
              />
              <div className="relative font-semibold">Secure and Private</div>
            </div>
            <div className="w-[280.8px] relative text-xl leading-[160%] font-body-2 text-black inline-block h-[184px] shrink-0">
              Your data security is at top priority. Rest assured your data is
              private and protected.
            </div>
          </div>
        </div>
        <div className="rounded-3xs bg-thistle flex flex-col items-center justify-center p-[30px] gap-[38px] text-28xl sm:w-auto sm:[align-self:unset] sm:pt-0">
          <div className="w-[705px] relative capitalize font-semibold inline-block h-[59px] shrink-0 sm:text-21xl sm:w-[400px] sm:flex-1">
            <p className="m-0">Introductory Offer for ITR Filing</p>
          </div>
          <div className="w-[711px] flex flex-row items-center justify-between text-left text-lg text-black font-body-2 sm:flex-col-reverse">
            <div className="flex flex-row items-end justify-start gap-[11px]">
              <div className="flex flex-col items-start justify-start gap-[8px]">
                <img
                  className="w-7 relative h-7"
                  alt=""
                  src="/interface--check.svg"
                />
                <img
                  className="w-7 relative h-7"
                  alt=""
                  src="/interface--check.svg"
                />
                <img
                  className="w-7 relative h-7"
                  alt=""
                  src="/interface--check.svg"
                />
                <img
                  className="w-7 relative h-7"
                  alt=""
                  src="/interface--check.svg"
                />
                <img
                  className="w-7 relative h-7"
                  alt=""
                  src="/interface--check.svg"
                />
                <img
                  className="w-7 relative h-7"
                  alt=""
                  src="/interface--check.svg"
                />
                <img
                  className="w-7 relative h-7"
                  alt=""
                  src="/interface--check.svg"
                />
                <img
                  className="w-7 relative h-7"
                  alt=""
                  src="/interface--check.svg"
                />
                <img
                  className="w-7 relative h-7"
                  alt=""
                  src="/interface--check.svg"
                />
              </div>
              <div className="flex flex-col items-start justify-start gap-[7px]">
                <div className="relative leading-[160%] capitalize">
                  Single and Multiple Employers
                </div>
                <div className="relative leading-[160%] capitalize">
                  Speculative Income
                </div>
                <div className="relative leading-[160%] capitalize">{`F&O Income/Loss (Non Audit)`}</div>
                <div className="relative leading-[160%] capitalize">
                  Crypto Income
                </div>
                <div className="relative leading-[160%] capitalize">
                  Rental Income
                </div>
                <div className="relative leading-[160%] capitalize">
                  Business Income (Non Audit)
                </div>
                <div className="relative leading-[160%] capitalize">
                  Capital Gain Income
                </div>
                <div className="relative leading-[160%] capitalize">
                  Agricultural Income
                </div>
                <div className="relative leading-[160%] capitalize">
                  Income from other sources
                </div>
              </div>
            </div>
            <div className="w-[370px] flex flex-row items-center justify-between text-center text-65xl text-teal">
              <div className="h-[318px] flex flex-col items-start justify-start py-[26px] px-[13px] box-border">
                <div className="w-[344px] relative h-[179.1px]">
                  <div className="absolute h-[81.3%] w-[84.07%] top-[-1.23%] left-[7.99%] leading-[140%] capitalize font-semibold inline-block">
                    <p className="m-0">₹ 999</p>
                    <p className="m-0">₹ 399</p>
                  </div>
                  <img
                    className="absolute h-[34.39%] w-[85.17%] top-[13.4%] right-[6.69%] bottom-[52.21%] left-[8.14%] max-w-full overflow-hidden max-h-full object-contain"
                    alt=""
                    src="/line-1.svg"
                  />
                  <img
                    className="absolute h-[33.05%] w-[86.05%] top-[13.4%] right-[5.81%] bottom-[53.55%] left-[8.14%] max-w-full overflow-hidden max-h-full object-contain"
                    alt=""
                    src="/line-2.svg"
                  />
                  <img
                    className="w-[370px] relative h-[318px] object-cover"
                    alt=""
                    src="/999-comp-2@2x.png"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="self-stretch bg-lightseagreen flex flex-col items-center justify-start py-[30px] px-0 gap-[40px] text-21xl text-icon-color">
        <div className="w-[351px] relative h-[50px]">
          <div className="absolute top-[0px] left-[0px] capitalize font-semibold inline-block w-[351px]" ref={aboutUsRef}>
            Meet the team
          </div>
        </div>
        <div className="w-[1264.5px] flex flex-row items-center justify-start gap-[61px] text-6xl sm:flex-col">
          <div className="self-stretch flex flex-col items-center justify-start gap-[20px]">
            <img
              className="w-[145px] relative h-[145px] object-cover"
              alt=""
              src="/ellipse-22@2x.png"
            />
            <div className="w-[270.4px] relative capitalize font-semibold inline-block">
              Hitik Patel
            </div>
            <div className="w-[265.4px] relative text-xl leading-[160%] capitalize font-body-2 inline-block">
              CA, GST Consultant
            </div>
          </div>
          <div className="self-stretch flex-1 flex flex-col items-center justify-start gap-[20px] sm:flex-[unset] sm:self-stretch">
            <img
              className="w-[145px] relative h-[145px] object-cover"
              alt=""
              src="/sani.png"
            />
            <div className="w-[270.4px] relative capitalize font-semibold inline-block">
              Sanidhya Somani
            </div>
            <div className="w-[265.4px] relative text-xl leading-[160%] capitalize font-body-2 inline-block">{`MBA (FMS Delhi), CFA l1, Certified Mutual Fund Advisor `}</div>
          </div>
          <div className="self-stretch flex-1 flex flex-col items-center justify-start gap-[20px] sm:flex-[unset] sm:self-stretch">
            <img
              className="w-[145px] relative rounded-[50%] h-[145px] object-cover"
              alt=""
              src="/krishna@2x.png"
            />
            <div className="w-[270.4px] relative capitalize font-semibold inline-block">Krishna Patel</div>
            <div className="w-[265.4px] relative text-xl leading-[160%] capitalize font-body-2 inline-block">
              <p className="m-0">Systematic Rule based Trader and Investor - 4 Yrs. Exp.</p>
              <p className="m-0">{` `}</p>
            </div>
          </div>
          <div className="self-stretch flex-1 flex flex-col items-center justify-start gap-[20px] sm:flex-[unset] sm:self-stretch">
            <img
              className="w-[145px] relative h-[145px] object-cover"
              alt=""
              src="/jignesh@2x.png"
            />
            <div className="w-[270.4px] relative capitalize font-semibold inline-block">
              Jignesh patel
            </div>
            <div className="w-[261px] relative text-xl leading-[160%] capitalize font-body-2 inline-block">
              CA Final, Tax Consultant, B Com in Accounting and Finance
            </div>
          </div>
        </div>
      </div>
      <div className="self-stretch bg-azure flex flex-row items-center justify-center p-[30px] gap-[400px] text-left font-body-2 sm:flex-col sm:gap-[10px_400px]">
        <div className="w-[349px] relative h-[177px]">
          <img
            className="absolute h-[20.34%] w-[10.6%] top-[6.21%] right-[87.11%] bottom-[73.45%] left-[2.29%] max-w-full overflow-hidden max-h-full"
            alt=""
            src="/phone.png"
          />
          <div className="absolute top-[0%] left-[17.77%] leading-[160%] capitalize"  ref={contactRef}>
            <p className="m-0">Krishna Patel</p>
            <p className="m-0">8952056684</p>
          </div>
          <div className="absolute h-[45.2%] w-full top-[54.8%] right-[0%] bottom-[0%] left-[0%] flex flex-row items-start justify-start text-center sm:items-center sm:justify-center">
            <div className="w-[45px] relative h-11 overflow-hidden shrink-0">
              <img
                className="absolute h-[58.41%] w-[75.11%] top-[20.91%] right-[12.44%] bottom-[20.68%] left-[12.44%] max-w-full overflow-hidden max-h-full"
                alt=""
                src="/vector.svg"
              />
            </div>
            <div className="relative leading-[160%] sm:ml-2">
              <p className="m-0">fizen.first@gmail.com</p>
            </div>
          </div>
        </div>
        <div className="h-[156px] flex flex-col items-end justify-center gap-[23px] text-2xl text-text-light font-text-md-medium sm:items-center sm:justify-center">
          <div className="rounded-tl-none rounded-tr-3xs rounded-br-3xs rounded-bl-none h-[68px] overflow-hidden shrink-0 hidden flex-row items-start justify-start">
            <input
              className="[border:none] [outline:none] font-body-2 text-xl bg-icon-color rounded-tl-md rounded-tr-none rounded-br-none rounded-bl-md box-border h-[68px] flex flex-row items-start justify-start py-[27px] px-11 text-silver border-t-[1px] border-solid border-darkgray border-b-[1px] border-l-[1px]"
              placeholder="Your Email / Phone no."
              type="text"
            />
            <div className="w-[200.6px] h-[68px] overflow-hidden shrink-0 flex flex-col items-center justify-center">
              <div className="self-stretch flex-1 overflow-hidden flex flex-row items-start justify-start">
                <div className="self-stretch flex-1 bg-sandybrown shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] overflow-hidden flex flex-row items-center justify-center py-0 px-5 relative gap-[8px]">
                  <div className="w-px absolute !m-[0] top-[calc(50%_-_0px)] left-[calc(50%_-_1.3px)] rounded-980xl bg-peru h-px overflow-hidden shrink-0 opacity-[0] z-[0]" />
                  <a className="hidden flex-row items-center justify-center z-[1]"

                  >
                    <img
                      className="w-[41px] relative h-[41px]"
                      alt=""
                      src="/whatsapp.svg"
                    />
                  </a>
                  <div className="relative leading-[24px] font-medium z-[2]">
                    Contact Me
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="rounded-3xs shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] overflow-hidden flex flex-col items-center justify-center text-base">
            <div className="overflow-hidden flex flex-row items-start justify-start">
              <a className="bg-mediumseagreen-100 shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] overflow-hidden flex flex-row items-center justify-center py-3 px-5 relative gap-[8px] no-underline"
                href="https://wa.me/918952056684?text=Hi%2C%20I%20am%20looking%20for%20tax%20services." target="_blank"
              >
                <div className="w-px absolute !m-[0] top-[calc(50%_+_0.5px)] left-[calc(50%_-_1px)] rounded-980xl bg-mediumseagreen-200 h-px overflow-hidden shrink-0 opacity-[0] z-[0]" />
                <div className="flex flex-row items-center justify-center z-[1]">
                  <img
                    className="w-[41px] relative h-[41px]"
                    alt=""
                    src="/whatsapp.svg"
                  />
                </div>
                <div className="relative leading-[24px] font-medium z-[2] text-white">
                  Connect on Whatsapp
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinalPc;
